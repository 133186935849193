import React from "react";

import {Switch, Case} from "components/layout/Switch";
import SearchBar from "components/search/SearchBar";
import SearchResults from "components/search/SearchResults";
import SplashBanner from "components/splash/SplashBanner";
import {ArrowClockwise} from "components/svg-icons";
import ThingTitle from "components/thing/ThingTitle";
import ThingForm from "components/thing/ThingForm";
import {editThing, peekSidePanel, popSidePanel} from "services/views";
import namespaceService from "services/namespaces";
import thingsService from "services/things";

const closeSidePanel = (store) => (event) => popSidePanel(store);

const refreshThingForm = (store, spec) => (event) => {
  closeSidePanel(store)(event);
  thingsService(store)
    .fetchThing(spec.thing)
    .then((thing) =>
      editThing(store, {id: thing.schemaId}, thing, spec.targetFormStateKey ?? null)
    );
};

const EmptyTitle = () => <React.Fragment />;

export default ({store}) => {
  const namespace = namespaceService(store).currentNamespace();
  // Use the whole side-panel stack to force React to re-instantiate
  // components on push/pop.
  const specs = (store.getState("config", "sidePanel") || []).slice().reverse();
  const currentSpec = peekSidePanel(store) ?? {};
  return (
    <>
      <div className="d-flex bg-light justify-content-start align-items-center p-2">
        <button
          type="button"
          className="btn btn-light"
          aria-label="Cerrar panel"
          onClick={closeSidePanel(store)}
          title="Cerrar panel"
        >
          <i className="fas fa-times" />
        </button>
        <span className="pl-2 mb-0 text-truncate flex-grow-1 h4">
          <Switch
            variable={currentSpec.action}
            defaultCase={EmptyTitle}
            store={store}
            spec={currentSpec}
            namespace={namespace}
          >
            <Case value="search" component={SearchBar} />
            <Case value="add-thing" component={ThingTitle} />
            <Case value="edit-thing" component={ThingTitle} />
          </Switch>
        </span>
        {currentSpec.action === "edit-thing" && (
          <button
            type="button"
            className="btn btn-light"
            aria-label="Actualizar"
            onClick={refreshThingForm(store, currentSpec)}
            title="Actualizar"
          >
            <ArrowClockwise />
          </button>
        )}
      </div>
      <div className="flex-grow-1 overflow-auto">
        {specs.map((spec, index) =>
          index < specs.length - 1 ? (
            <React.Fragment key={`spec-${index}`} />
          ) : (
            <Switch
              key={`spec-${index}`}
              variable={spec.action}
              defaultCase={SplashBanner}
              store={store}
              spec={spec}
              namespace={namespace}
            >
              <Case value="search" component={SearchResults} />
              <Case value="add-thing" component={ThingForm} />
              <Case value="edit-thing" component={ThingForm} />
            </Switch>
          )
        )}
      </div>
    </>
  );
};
