import React from "react";

import {Switch, Case} from "components/layout/Switch";
import PluralField from "components/thing/fields/PluralField";
import thingService from "services/things";
import {MAX_SUGGESTIONS} from "conf";
import {isDefined} from "utils";

const TypedTextField = ({inputId, initialState, saveState, prop, schema, store}) => {
  const input = React.useRef();
  const pending = React.useRef(true);
  React.useEffect(() => {
    if (input.current && pending.current) {
      input.current.value = initialState ?? "";
      pending.current = false;
      saveState(initialState ?? "");
    }
  });

  // Handle suggestions
  const [value, setValue] = React.useState(initialState);
  const previousStoreValues = React.useMemo(() => {
    if (prop.multiline || !prop.suggestChoices) {
      return [];
    }
    const items =
      store.getState(
        "resource",
        thingService(store).resourceKey(schema),
        "data",
        "items"
      ) ?? [];
    const {key, singular} = prop;
    const keys = {};
    items
      .flatMap((it) => (singular ? [it.data[key]] : it.data[key] ?? []))
      .filter((option) => option !== null && option.trim() !== "")
      .map((option) => option.trim())
      .forEach((option) => {
        keys[option.toLowerCase()] = {
          count: (keys[option.toLowerCase()]?.count ?? 0) + 1,
          option,
        };
      });
    return Object.values(keys)
      .sort((a, b) => {
        if (a.count < b.count) {
          return -1;
        }
        if (b.count > a.count) {
          return 1;
        }
        return 0;
      })
      .map(({option}) => option);
    // eslint-disable-next-line
  }, [store.getState("resource", "_changeCounter")]);
  const suggestions = React.useMemo(() => {
    const searchString = (value ?? "").trim().toLowerCase();
    return previousStoreValues
      .filter((p) => p.toLowerCase().indexOf(searchString) !== -1)
      .slice(0, MAX_SUGGESTIONS);
  }, [value, previousStoreValues]);

  return (
    <>
      {prop.singular && (
        <label htmlFor={inputId}>
          <strong>{prop.propName}</strong>
        </label>
      )}
      {!prop.multiline && prop.suggestChoices && (
        <div className="mb-1 d-flex justify-content-between w-100">
          {suggestions.length > 0 ? (
            suggestions.map((text) => (
              <button
                key={`suggestion(${text})`}
                type="button"
                className="btn btn-sm btn-light text-truncate"
                title={text}
                onClick={() => {
                  input.current.value = text;
                  saveState(text);
                }}
              >
                {text}
              </button>
            ))
          ) : (
            <p className="text-muted mb-2">
              <small>No hay sugerencias</small>
            </p>
          )}
        </div>
      )}
      {prop.multiline ? (
        <textarea
          id={inputId}
          className="form-control"
          rows="3"
          required={prop.required}
          placeholder={prop.propName}
          onBlur={(evt) => saveState(input.current.value ?? "")}
          ref={input}
        ></textarea>
      ) : (
        <input
          type="text"
          id={inputId}
          className="form-control"
          required={prop.required}
          placeholder={prop.propName}
          onChange={(evt) => setValue(evt.target.value)}
          onBlur={(evt) => saveState(input.current.value ?? "")}
          ref={input}
        />
      )}
    </>
  );
};

const RestrictedTextField = ({inputId, initialState, saveState, prop}) => {
  const input = React.useRef();
  const pending = React.useRef(true);
  React.useEffect(() => {
    if (input.current && pending.current) {
      input.current.value = isDefined(initialState) ? initialState : "";
      pending.current = false;
      saveState(isDefined(initialState) ? initialState : "");
    }
  });

  return (
    <>
      {prop.singular && (
        <label htmlFor={inputId}>
          <strong>{prop.propName}</strong>
        </label>
      )}
      <select
        id={inputId}
        className="form-control"
        required={prop.required}
        onChange={(evt) => saveState(evt.target.value)}
        ref={input}
      >
        <option value="">Seleccionar {prop.propName.toLowerCase()}</option>
        {prop.restrictedChoices.map((v, index) => (
          <option key={`choice-${index}`} value={v}>
            {v}
          </option>
        ))}
      </select>
    </>
  );
};

const TextField = ({prop, ...props}) => (
  <Switch
    variable={Boolean(prop.restrictedChoices)}
    defaultCase={TypedTextField}
    prop={prop}
    {...props}
  >
    <Case value={false} component={TypedTextField} />
    <Case value={true} component={RestrictedTextField} />
  </Switch>
);

export default (props) => <PluralField component={TextField} {...props} />;
