import React from "react";

import AsyncComponent from "components/AsyncComponent";
import ShareButton from "components/thing/ShareButton";
import ThingActionButton from "components/thing/ThingActionButton";
import DeleteButton from "components/thing/DeleteButton";
import {
  BooleanField,
  DateField,
  DateTimeField,
  FileField,
  NumberField,
  RelationField,
  TextField,
} from "components/thing/fields";
import namespaceService from "services/namespaces";
import thingService from "services/things";
import {
  popSidePanelActions,
  cancelAction,
  transformSidePanelIntoAddThing,
} from "services/views";
import {TOAST_SHOWN, FORM_STATE_SAVED} from "state/actions";
import * as errors from "errors";
import {isDefined} from "utils";

export default class ThingForm extends AsyncComponent {
  get service() {
    return thingService(this.props.store);
  }

  get namespace() {
    return namespaceService(this.props.store).currentNamespace();
  }

  get schema() {
    return (this.namespace.schemas ?? []).find(({id}) => id === this.props.spec.schemaId);
  }

  get thingProps() {
    return this.schema?.contents?.thingProps ?? [];
  }

  get editMode() {
    return this.props.spec.action === "edit-thing";
  }

  get pristineFormState() {
    return this.editMode ? this.props.spec.thing.data : {};
  }

  get currentFormState() {
    return {
      ...this.pristineFormState,
      ...(this.props.store.getState("form", this.props.spec.formStateKey) ?? {}),
    };
  }

  takeFormStateSnapshot() {
    this.props.store.dispatch(
      FORM_STATE_SAVED.make({
        key: this.props.spec.formStateKey,
        value: this.currentFormState,
      })
    );
  }

  saveFormState(key) {
    return (value) =>
      this.props.store.dispatch(
        FORM_STATE_SAVED.make({
          key: this.props.spec.formStateKey,
          value: {
            ...(this.props.store.getState("form", this.props.spec.formStateKey) ?? {}),
            [key]: value,
          },
        })
      );
  }

  save(event) {
    event.preventDefault();
    return (this.editMode
      ? this.service.updateThing(this.props.spec.thing, this.currentFormState)
      : this.service.createThing(this.schema, this.currentFormState)
    ).then(
      (thing) => {
        const actions = [];
        // If this form was triggered from another, set the
        // created/updated thing to the form state that requested it
        if (this.props.spec.targetFormStateKey) {
          const [formStateKey, propKey] = this.props.spec.targetFormStateKey;
          actions.push(
            FORM_STATE_SAVED.make({
              key: formStateKey,
              value: {
                ...(this.props.store.getState("form", formStateKey) ?? {}),
                [propKey]: thing,
              },
            })
          );
        }
        // Close the form
        popSidePanelActions(this.props.store).forEach((action) => actions.push(action));
        // Notify the successful creation
        actions.push(
          TOAST_SHOWN.make({
            icon: "info",
            text: [
              this.schema.contents.thingName,
              this.editMode ? "guardada(o)" : "creada(o)",
              "correctamente",
            ].join(" "),
          })
        );
        return this.props.store.dispatch(...actions);
      },
      this.async((err) => {
        if (err instanceof errors.RequestError) {
          // A bad request means validation didn't pass
          TOAST_SHOWN.dispatch(this.props.store, {
            icon: "danger",
            text: "Hay errores en el formulario; corrígelos e intenta de nuevo",
            timeout: 5000,
          });
        } else if (err instanceof errors.NotFoundError) {
          // A not found means that the thing was deleted by someone,
          // so the current user might want to re-create it now
          TOAST_SHOWN.dispatch(this.props.store, {
            icon: "warning",
            text: [
              `La/el ${this.schema.contents.thingName.toLowerCase()}`,
              "fue eliminado por otra persona; puedes crearlo de nuevo aquí",
            ].join(" "),
            timeout: 5000,
          });
          this.takeFormStateSnapshot();
          const thing = this.props.spec.thing;
          transformSidePanelIntoAddThing(this.props.store);
          this.service.deleteThingReferences(thing);
        } else {
          console.error(err);
          TOAST_SHOWN.dispatch(this.props.store, {
            icon: "danger",
            text: [
              `Hubo un problema al ${this.editMode ? "guardar" : "crear"}`,
              `la/el ${this.schema.contents.thingName.toLowerCase()};`,
              "inténtelo más tarde",
            ].join(" "),
            timeout: 5000,
          });
        }
      })
    );
  }

  isSaving() {
    if (this.editMode) {
      return this.service.updateOngoing(this.props.spec.thing);
    }
    return this.service.createOngoing(this.schema);
  }

  deleteThing() {
    return this.service.deleteThing(this.props.spec.thing).then(
      () =>
        this.props.store.dispatch(
          // Notify the successful deletion
          TOAST_SHOWN.make({
            icon: "info",
            text: `${this.schema.contents.thingName} eliminada(o) correctamente`,
          }),
          // Close the form
          ...popSidePanelActions(this.props.store)
        ),
      (err) => {
        console.error(err);
        TOAST_SHOWN.dispatch(this.props.store, {
          icon: "danger",
          text: [
            "Hubo un problema al eliminar",
            `la/el ${this.schema.contents.thingName.toLowerCase()};`,
            "inténtelo más tarde",
          ].join(" "),
          timeout: 5000,
        });
      }
    );
  }

  isDeleting() {
    return this.editMode && this.service.deleteOngoing(this.props.spec.thing);
  }

  isFetching() {
    return (
      this.editMode && this.service.anyFetchOngoing({id: this.props.spec.thing.schemaId})
    );
  }

  renderField(prop) {
    const props = {
      inputId: `${this.props.spec.formStateKey}:${prop.key}`,
      pristineState: (prop.singular
        ? [this.pristineFormState[prop.key]]
        : this.pristineFormState[prop.key] ?? []
      ).filter(isDefined),
      initialState: this.currentFormState[prop.key],
      saveState: this.saveFormState(prop.key).bind(this),
      formStateKey: this.props.spec.formStateKey,
      prop,
      schema: this.schema,
      store: this.props.store,
    };
    switch (prop.type) {
      case "text":
        return <TextField {...props} />;
      case "number":
        return <NumberField {...props} />;
      case "date":
        return <DateField {...props} />;
      case "datetime":
        return <DateTimeField {...props} />;
      case "boolean":
        return <BooleanField {...props} />;
      case "file":
        return <FileField {...props} />;
      case "relation":
        return <RelationField {...props} />;
      default:
        console.warn("Unexpected prop type:", prop.type);
        return null;
    }
  }

  renderButtons() {
    return (
      <>
        <button type="submit" className="btn btn-primary btn-block btn-lg">
          GUARDAR
        </button>
        {this.editMode && (
          <ShareButton
            store={this.props.store}
            thing={this.props.spec.thing}
            modalKey={`${this.props.spec.formStateKey}:share`}
          />
        )}
        {this.editMode &&
          (this.schema.contents.thingActions ?? []).map((action, index) => (
            <ThingActionButton
              key={`${this.schema.contents.name}-action-${index}`}
              action={action}
              namespace={this.namespace}
              schema={this.schema}
              thing={this.props.spec.thing}
            />
          ))}
        <button
          type="button"
          className="btn btn-secondary btn-block btn-lg"
          onClick={() => cancelAction(this.props.store)}
        >
          CANCELAR
        </button>
        {this.editMode && (
          <DeleteButton
            store={this.props.store}
            schema={this.schema}
            thing={this.props.spec.thing}
            onDelete={this.deleteThing.bind(this)}
            modalKey={`${this.props.spec.formStateKey}:delete`}
          />
        )}
      </>
    );
  }

  render() {
    return (
      <form
        className="mx-3"
        onSubmit={this.save.bind(this)}
        onKeyDown={(evt) => (evt.key === "Enter" ? evt.preventDefault() : null)}
      >
        <fieldset disabled={this.isSaving() || this.isDeleting() || this.isFetching()}>
          {this.thingProps.map((prop) => (
            <div key={prop.key} className="form-group">
              {this.renderField(prop)}
            </div>
          ))}
          {this.renderButtons()}
        </fieldset>
      </form>
    );
  }
}
